import React, { lazy, Suspense } from 'react';

const IntroTextMatPredstavuWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextMatPredstavuWebStrankyCChLazy' */ './IntroTextMatPredstavuWebStrankyCCh'));

function IntroTextMatPredstavuWebStrankyCChLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextMatPredstavuWebStrankyCCh ...</div>}>
                <IntroTextMatPredstavuWebStrankyCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextMatPredstavuWebStrankyCChLazy;